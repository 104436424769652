import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SidebarForm from "../components/SidebarForm"
import { StaticImage } from "gatsby-plugin-image"

const GallerySinglePage = () => (
    <Layout>
        <div className="bg-white drop-shadow-xl border-t-7 md:px-8 px-4 mt-8 pb-6">
            <div className="flex">
            <div className="md:w-8/12 w-full">
            <h2 className="text-3xl text-gray-600 leading-7 uppercase font-light title pb-5 mt-7 text-center mb-8">BROWN FENCE PRIVACY WITH ALUMINUM TOP, TORONTO</h2>
           <StaticImage src="../assets/images/gallery/pic-1.jpg" alt="" />
           <p className="text-lg mt-4 font-normal">Product Number : <b>FToronto</b></p>
           </div>
           <div className="md:w-4/12 w-full">
           <SidebarForm/>
            </div>
           </div>
        </div>
    </Layout>
)
export default GallerySinglePage